import React, { useEffect, useState } from "react";
import { Buttons, Cards, Navbar, NeftForm, StudentIDCard } from "./Navbar";
import './Dash.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Dashboard = () => {
  const [feeStructure, setFeeStructure] = useState([])
  const [allFee, setallFee] = useState([{ head_name: '' }]);
  const [data, setData] = useState([]);
  const [SingleFee, setSingleFee] = useState([])
  const [isDone, setisDone] = useState([])
  const [isChecked, setChecked] = useState(false);
  const [isCheckedn, setCheckedn] = useState(false);
  const [ddlink, setDDlink] = useState(false);
  const [balance, setBalance] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    FetchFeeStructure();
    FetchAllFh();
    fetchAmount();
    if(!localStorage.getItem('token')){
      navigate('/login');
    }
  }, []);

  const FetchAllFh = async () => {

    try {
      const { data,status } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFh`,{headers:{
        Authorization : localStorage.getItem('token')
      }});
      if (!data.found) console.log(data.error);
      if(status==401 || status==403){
        alert('Session Expired');
        localStorage.clear();
      }
      else {
        setallFee(data.result);
      }
    } catch (error) {
      alert('Session Expired');
        localStorage.clear();
      window.location.reload();

    }
  };

  const FetchFeeStructure = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/feeStructureStud`,{headers:{
        Authorization : localStorage.getItem('token')
      }});
      const data = await response.data;

      if (!data.found) {
        console.log(data.error);
      }
      else {
        setFeeStructure(data.result);

        try{
          const response1 = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/FetchBalanceFee_uid`,{headers:{
              Authorization : localStorage.getItem('token')
            }}
          );
          const data1 = await response1.data;
    
          if(data1.result.length>0){
            setBalance(data1.result);
          }
          else{
    
            setBalance(data.result);
          }
        }
        catch(err){
          console.log(err);
        }
      }
    } catch (error) {
      alert(error);
    }
    
  }

  const fetchAmount = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/single_fee_amounts`,{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          setSingleFee(response.data);
        })
        .catch(err=>
          {
          console.log(err);
          }
      );
  }

  var FeeItems = []
  if(SingleFee.length>0){
    for(var i=0;i<SingleFee.length;i++){
      FeeItems.push(
        <div class="card col-lg-3" style={{minWidth: "18rem",width:"fit-content"}}>
        <div class="card-header">
          {SingleFee[i].head_name}
        </div>
        <div class="card-body">
          <h5 class="card-title m-3">{SingleFee[i].amount} Rs.</h5>
          <form action={`https://vppcoe-va.getflytechnologies.com/payment/student_initiate_payment?id=${SingleFee[i].sfh_id}`} method='POST' id='integration' >
                <button className='btn btn-primary' name='collegeId' value={localStorage.getItem('stud_clg_id')}>
                  Pay Fees
                </button>
          </form>
        </div>
      </div>
      )

    }
  }

  var sum = 0;

  for (let i = 0; i < feeStructure.length; i++) {
    sum = sum + feeStructure[i].amount;
  }


  if(balance.length>0){
    var bsum = 0;

    for (let i = 0; i < feeStructure.length; i++) {
      bsum = Number(bsum) + Number(balance[i].balance_amount);
    }
  }
  

  var feeStructureItems = []
  try {
    
      feeStructure.map((data, index) => feeStructureItems.push(
        <tr key={index}>
          <td style={styles.tableCell}>{allFee.find(e => e.fh_id == data.fh_id).head_name}</td>
          <td style={styles.tableCell}>{data.amount}</td>
        </tr>
      ))
    
  }
  catch (err) {
    console.log(err)
  }

  
  return (
    <>
      <div className="mycontainer_withH">
      <div className="container dashboard" >
     
     <StudentIDCard 
     element={
      <>
       <div className="row">
       <div className="col-lg-6 col-md-12 mx-auto p-0">
         <Cards
           title={"Payable Amount"}
           text={sum}
           button={<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1">
          View Fee Structure
         </button>}
         />
       </div>
       <div className="col-lg-6 col-md-12 mx-auto">
         <Cards
           title={"Balance Amount"}
           text={bsum}
           button={<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
           Pay Now
         </button>}
         />
       </div>
     </div>
      </>
     }
     />
    
      </div>
   </div>

   <div className="container mb-4">
    <div className="row justify-content-between g-2" style={{width:'100%',flexWrap:"wrap"}}>
        {FeeItems}
    </div>
   </div>
   




<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Select Mode Of Payment</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <Buttons />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Fee Structure</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {(feeStructure.length > 0) && (
              <div style={styles.tableContainer}>
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          ...styles.tableCell,
                          background: "#f6f6f6",
                          textAlign: "left",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          ...styles.tableCell,
                          background: "#f6f6f6",
                          textAlign: "left",
                        }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      feeStructureItems
                    }
                    <tr>
                      <td style={styles.tableCell}>Total</td>
                      <td style={styles.tableCell}>{sum}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>
    </>
 
  );
};

export default Dashboard;


const styles = {
  mainContent: {
    flex: 1,
    padding: "20px",
  },

  formContainer: {
    padding: "20px",
    borderRadius: "4px",
    flex: "none",
  },

  inputGroup: {
    fontWeight: "bold",
  },
  label: {
    marginRight: "10px",
    padding: "10px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  input: {
    flex: "0 0 60px",
    border: "1px solid #ccc",
    transition: "border-color 0.3s ease",
    padding: "8px",
    marginRight: "30px",
    marginBottom: "20px",
    marginLeft: "20px",
    marginTop: "20px",
    borderRadius: "4px",
    borderColor: "#4d4d4d",
  },
  buttonContainer: {
    display: "flex",
  },
  submitButton: {
    padding: "10px 20px",
    backgroundColor: "#00b695",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "70px",
    marginBottom: "30px",
  },

  tableContainer: {
    maxHeight: "400px",
    border: "0.5px solid #e6e6e6",
    maxWidth: "100%",
    background: "white",
    borderRadius: "4px",
    overflow: "hidden",
    margin: "10px 0", // Increase the margin to create a bigger cell gap
  },

  table: {
    borderCollapse: "collapse",
    width: "100%",
  },

  tableCell: {
    border: "1px solid #e6e6e6",
    padding: "8px",
    height: "40px", // Adjust the height as needed
    verticalAlign: "middle",
  },
};

